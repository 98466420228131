// because vite generated code does not go through babel
import '../css/app.pcss';

import components from './_importComponents';

// Add JS Loaded class to the body
document.documentElement.classList.add('js-loaded');

// App main
const main = () => {
  components();
};

main();
